<template>
    <div class="box">
        <!-- 背景 -->
        <div class="bg"><img src="../../assets/shop/shopbg.png"></div>
        <!-- 内容 -->
        <div class="centent">
            <!-- 顶部内容 -->
            <div class="title">
                <div class="titleA">
                    <div>{{ shopList.Title }}</div>
                    <div class="titleC">
                        <img src="../../assets/shop/shopa.png">
                        <span style="font-size:16px;color:#F09B0B">{{ shopList.Level }}</span>
                        <img src="../../assets/shop/shopb.png">
                        <span>8:30至19:00</span>
                        <img src="../../assets/shop/shopc.png">
                        <span>{{ shopList.Address }}</span>
                    </div>
                </div>
                <img :src="shopList.ShopImgUrl" class="titleB">
            </div>
            <!-- tabs切换 -->
            <van-tabs v-model="active" color="#fed456">
                <van-tab title="商品">
                    <div class="listBox">
                        <div class="listBoxLeft">
                            <div v-for="item in typeList" :key="item.SortId"
                                :class="{ 'selected': isChoose == item.SortId }" @click="goodsTypeClick(item.SortId)">
                                {{ item.Name }}</div>
                        </div>
                        <div class="listBoxRight">
                            <div class="list" v-for="item in list" :key="item.GoodsId">
                                <img :src="item.ImgUrl" />
                                <div class="listRight">
                                    <div>{{ item.Name }}</div>
                                    <div>{{ item.SortName }}</div>
                                    <div>
                                        <span><span style="color: #ff4729;font-size: 12px;">¥</span><span
                                                style="color: #ff4729;">{{ item.Amount }}{{ item.MUnit }}</span></span>
                                        <!-- <span class="addcar">+</span> -->
                                        <van-stepper v-model="item.num" min="" integer input-width="25px"
                                            button-size="25px" default-value="0" 
                                            style="position: absolute;bottom: 0px;background: none;margin:0px;padding:0px"
                                            @change="addOrder(item)" allow-empty="true" />
                                    </div>
                                </div>
                            </div>
                            <van-empty :image-size="[30, 20]" description="暂无商铺内容~" v-if="!list.length" />
                        </div>

                    </div>
                </van-tab>
                <van-tab title="评价">
                    <!-- 评分 -->
                    <div class="evaluation">
                        <div class="fenT">
                            <div>{{ shopList.Level }}</div>
                            <div>商家评分</div>
                        </div>
                        <div class="xing">
                            <div style="margin-bottom:6px">
                                <span>等级</span>
                                <span>
                                    <van-rate v-model="shopList.Level" :size="25" color="#ffd21e" void-icon="star"
                                        void-color="#eee" readonly />
                                </span>
                                <span>{{ shopList.Level }}</span>
                            </div>
                        </div>
                    </div>
                    <!-- 评价 -->
                    <!-- <div class="evaluationBox">
                        <div class="evaluationList">
                            <div class="evaluationTitle">
                                <img src="../../assets/img_personal@2x.png">
                                <div class="fen">
                                    <div style="margin-bottom: 3px;">ETN63638407用户</div>
                                    <div>
                                        <span style="font-size: 13px;margin-right: 5px;">评分</span>
                                        <van-rate v-model="value" :size="16" color="#ffd21e" void-icon="star"
                                            void-color="#eee" readonly />
                                    </div>
                                </div>
                                <div class="time">2022-07-18</div>
                            </div>
                            <div class="evaluationCenter">
                                真的很满意这次购物,可乐还是凉的,赠送的小三瓶也很方便携带,好评
                            </div>
                        </div>
                    </div> -->
                </van-tab>
                <van-tab title="商家">
                    <div>
                        <van-cell-group>
                            <van-cell title="负责人" :value="shopList.Charger" />
                            <van-cell title="手机号" :value="shopList.Mobile" />
                            <van-cell title="商品行业" :value="shopList.ShopTypeDesc" />
                            <van-cell title="店铺等级" :value="shopList.LevelDesc" />
                            <van-cell title="商铺地址" :label="shopList.Address" />
                            <van-cell :value="shopList.Remark" v-if="shopList.Remark" />
                        </van-cell-group>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
        <!-- 底部按钮 -->
        <div class="button">
            <van-badge :content="num">
                <img src="../../assets/shop/icon-waimai.png" style="width:35px;margin: 5px 5px 5px 25px;">
            </van-badge>
            <span style="padding-left: 8px;font-size: 12px;letter-spacing: 1px;" v-if="amount != 0">当前费用大约¥{{ amount
            }}元</span>
            <span style="padding-left: 8px;font-size: 12px;" v-else>暂无选择商品,请选择您心仪的商品~</span>
            <div class="order" @click="orderOn">下订单</div>
        </div>
        <!-- 订单列表 -->
        <van-popup v-model="orderListShow" round position="bottom" :style="{ height: '70%' }">
            <div class="listBox">
                <div class="listBoxRight" style="width: 90%;margin: 20px 5%;">
                    <div class="list" v-for="item in orderList" :key="item.GoodsId">
                        <img :src="item.GoodsImgUrl" />
                        <div class="listRight" style="width:70%">
                            <div>
                                {{ item.GoodsName }}
                                <span style="float:right">
                                    <span style="color: #ff4729;font-size: 12px;">¥</span>
                                    <span style="color: #ff4729;">{{ item.Price }}{{ item.MUnit }}</span>
                                </span>
                            </div>
                            <span style="position: absolute;right: 0px;bottom: 0px;background: #f2f3f5;margin:0px;padding:5px 10px;font-size: 14px;">{{item.GoodsNum}}</span>
                            <span class="goodsDelete" @click="deleteOrder(item)">删除</span>
                        </div>
                    </div>
                    <van-empty :image-size="[30, 20]" description="订单暂无内容~" v-if="!orderList.length" />
                </div>
            </div>
            <div class="button">
                <van-badge :content="num">
                    <img src="../../assets/shop/icon-waimai.png" style="width:35px;margin: 5px 5px 5px 25px;">
                </van-badge>
                <span style="padding-left: 8px;letter-spacing: 1px;font-size: 12px;">当前费用大约¥{{ amount }}元</span>
                <div class="order" @click="PlaceOrder">确定</div>
            </div>
        </van-popup>
        <van-popup v-model="consigneeShow" round position="bottom" :style="{ height: '50%' }">
            <div style="text-align: center;font-size: 16px;font-weight: 700;margin: 15px 0px;">收货人列表</div>
            <van-radio-group v-model="checked">
                <van-radio v-for="item in consignee" :key="item.BindId" :name="item.BindId" @click="checkedClick(item)">
                    <div style="margin-left: 15px;">
                        <div>
                            <span style="font-size: 16px;color: #3a95cc;">{{ item.OMName }}</span>
                            <span style="font-size: 14px;padding-left: 15px;">{{ item.OMMobile }}</span>
                        </div>
                        <div style="font-size: 14px;color: #333;">{{ item.OMAddr }}</div>
                    </div>
                </van-radio>
            </van-radio-group>
            <div class="saveOn" @click="saveFormOn">提 交</div>
        </van-popup>
    </div>
</template>
<script>
import {
    WxGetGoodsSortList,
    WxGetGoodsPage,
    WxGetMyShop,
    WeGenerateOrder,
    WeSaveOrderService,
    WeDeleteOrderGoods,
    WeGetOrderGoodsList,
    WePlaceOrder,
    WeGetMyBindInfoPage
} from '@/api/shop';
import { getOpenId } from "@/utils/auth";
import Vue from "vue";
import { Toast } from "vant";
import { Dialog } from 'vant';
Vue.use(Toast);
Vue.use(Dialog);
export default {
    data() {
        return {
            active: 0, //tab切换
            isChoose: false,
            value: 4.8,
            typeList: [], //商品类型
            list: [], //商品列表
            shopList: [], //商铺信息
            shopId: "",
            openId: "",
            orderListShow: false,
            consigneeShow: false,
            orderForm: { //订单
                OpenID: "oebDw5XNiVzsIUaP__HJXNUd6krs",
                // OpenID: getOpenId(),
                GoodsNum: 0,
                OrderId: undefined,
                GoodsId: undefined,
                BindId: undefined,
            },
            orderList: [], //已点订单内容
            amount: 0,//金额
            num: 0, //数量
            checked: undefined,
            consignee: [],//收货人列表
        }
    },
    mounted() {
        this.shopId = this.$route.query.Id;
        // this.openId = getOpenId();
        this.openId = "oebDw5XNiVzsIUaP__HJXNUd6krs";
        this.getgoodsShop();
        this.getTypeList();
        this.getGoddPage();
        this.generateOrder();
        this.getMyBindInfoPage();
    },
    methods: {
        // 生成订单
        generateOrder() {
            WeGenerateOrder({ OpenID: this.openId, ShopId: this.shopId }).then((res) => {
                if (res.data.code == 0) {
                    this.orderForm.OrderId = res.data.data;
                    this.getOrderGoodsList();
                } else {
                    Toast(res.data.msg);
                }
            })
        },
        // 类型选择
        goodsTypeClick(row) {
            this.isChoose = row;
            this.getGoddPage();
        },
        // 获取商品类型
        getTypeList() {
            WxGetGoodsSortList({ shopId: this.shopId }).then((res) => {
                console.log(res);
                if (res.data.code == 0) {
                    if (res.data.data.length != 0) {
                        this.typeList = res.data.data;
                        this.sortId = this.typeList[0].SortId;
                    } else {
                        this.typeList = [
                            { SortId: 0, Name: "暂无分类" }
                        ]
                    }
                }
            })
        },
        // 获取商品列表
        getGoddPage() {
            WxGetGoodsPage({ shopId: this.shopId, sortId: this.isChoose }).then((res) => {
                console.log(res);
                if (res.data.code == 0) {
                    this.list = res.data.data;
                    for (let i = 0; i < this.list.length; i++) {
                        this.list[i].num = 0;
                    }
                    console.log(this.list);
                }
            })
        },
        // 获取商铺详情
        getgoodsShop() {
            WxGetMyShop({ shopId: this.shopId }).then((res) => {
                this.shopList = res.data.data;
                if (this.shopList.ShopImg == "") {
                    this.shopList.ShopImgUrl = "https://lyxnyapi.dexian.ren//upload/images/2022/7/b776c8ca-b1f.png"
                }
            })
        },
        // 增加商品数量
        addOrder(row) {
            console.log(row);
            this.orderForm.GoodsId = row.GoodsId;
            this.orderForm.GoodsNum = row.num;
            WeSaveOrderService(this.orderForm).then((res) => {
                this.getOrderGoodsList();
            })
        },
        // 删除商品订单
        deleteOrder(row) {
            this.orderForm.OGoodsId = row.OGoodsId;
            WeDeleteOrderGoods(this.orderForm).then((res) => {
                this.getOrderGoodsList();
            })
        },
        // 下单按钮
        orderOn() {
            this.orderListShow = true;
        },
        // 获取订单列表
        getOrderGoodsList() {
            WeGetOrderGoodsList(this.orderForm).then((res) => {
                this.orderList = res.data.data;
                this.num = this.orderList.length;
                this.amount = 0;
                console.log(this.orderList);
                for (let i = 0; i < this.orderList.length; i++) {
                    this.amount += this.orderList[i].Amount;
                }
            })
        },
        // 确认订单
        PlaceOrder() {
            if (this.orderList.length == 0) {
                Toast.fail('请先选择商品!');
            } else {
                this.consigneeShow = true;
            }
        },
        // 获取绑定信息
        getMyBindInfoPage() {
            WeGetMyBindInfoPage(this.orderForm).then((res) => {
                if (res.data.count != 0) {
                    this.consignee = res.data.data;
                } else {
                    this.consignee = [];
                    Toast.fail('请先绑定收货人信息!');
                }
            })
        },
        // 收货人选中
        checkedClick(row) {
            this.checked = row.BindId;
            this.orderForm.BindId = row.BindId;;
        },
        // 提交订单
        saveFormOn() {
            console.log(this.orderForm);
            WePlaceOrder(this.orderForm).then((res) => {
                if (res.data.code == 0) {
                    this.orderListShow = false;
                    this.consigneeShow = false;
                    this.getOrderGoodsList();
                    Toast.success('提交成功!');
                }
            })
        },
        // 数量修改
        numAmend(row) {
            console.log(row);
        }
    },
}
</script>
<style scoped>
.box {
    height: 100vh;
    min-height: 100vh;
    overflow: hidden;
}

.bg {
    position: absolute;
    background-color: rgba(0, 0, 0, .4);
}

.bg img {
    width: 100%;
}

.centent {
    position: relative;
    top: 10vh;
    background: #fff;
    height: 90vh;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.centent .title {
    position: relative;
}

.centent .title .titleA {
    font-weight: 700;
    padding: 10px 15px;
    font-size: 18px;
}

.centent .title .titleB {
    position: absolute;
    right: 25px;
    top: -30px;
    width: 65px;
    height: 65px;
    border-radius: 5px;
}

.centent .title .titleC {
    display: flex;
    font-size: 12px;
    margin: 5px 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 400;
}

.centent .title .titleC span {
    margin: 0px 6px;
}

.centent .title .titleC img {
    width: 15px;
    height: 15px;
}

.listBox .listBoxLeft {
    display: inline-block;
    float: left;
    width: 30%;
    background: #fafafb;
    height: 80vh;
    overflow-y: scroll;
}

.listBox::-webkit-scrollbar {
    display: none;
}

.listBox .listBoxRight {
    display: inline-block;
    float: left;
    width: 65%;
    margin: 5px 9px;
    height: 80vh;
    overflow-y: scroll;
}

.listBoxRight .list {
    display: flex;
    position: relative;
    margin-bottom: 15px;
}

.listBoxRight .list img {
    width: 100px;
    height: 100px;
    border-radius: 8px;
}

.listBoxRight .list .listRight {
    width: 100%;
}

.list .listRight div:nth-child(1) {
    float: left;
    font-size: 15px;
    font-weight: 700;
    margin: 0px 5px;
    width: 100%;
}

.list .listRight div:nth-child(2) {
    float: left;
    font-size: 12px;
    padding: 3px 8px;
    margin: 5px 5px;
    background: #e9a314;
    color: #fff;
    border-radius: 5px;
}

.list .listRight div:nth-child(3) {
    margin: 0px 5px;
    width: 95%;
    line-height: 35px;
    font-size: 12px;
}

.list .listRight .addcar {
    width: 20px;
    height: 20px;
    display: inline-block;
    background: #ffca36;
    border-radius: 5px;
    text-align: center;
    line-height: 20px;
    font-size: 20px;
    position: absolute;
    right: 0px;
}

.listBox .listBoxLeft div {
    padding: 14px 0px;
    text-align: center;
    font-size: 14px;
    color: #6A6A6A;
}

.listBox .listBoxLeft div:nth-last-child(1) {
    /* padding-bottom: 25px */
}

.listBox .listBoxLeft .selected {
    position: relative;
    background: #fff;
    color: #232325;
    font-weight: 500;
}

.listBox .listBoxLeft .selected::before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 3px;
    height: 100%;
    background: #fed456;
}

.button {
    display: flex;
    position: fixed;
    bottom: 10px;
    width: 95%;
    height: 45px;
    line-height: 45px;
    margin: 0px 2.5%;
    background: #232325;
    color: #fff;
    border-radius: 30px;
    font-size: 14px;
}

.button .order {
    position: absolute;
    top: 0px;
    right: -1px;
    width: 80px;
    height: 45px;
    line-height: 45px;
    background: linear-gradient(to bottom right, #ffca36, #ffca36);
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    text-align: center;
    letter-spacing: 1px;
    color: #252523;
    font-weight: 500;
}

.evaluation {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 70px;
}

.evaluation .fenT {
    width: 30%;
}

.evaluation .xing {
    width: 70%;
}

.evaluation .fenT div:nth-child(1) {
    font-size: 28px;
    color: #f4ae19;
    text-align: center;
}

.evaluation .fenT div:nth-child(2) {
    text-align: center;
    font-size: 13px;
    color: #909091;
}

.evaluation .xing div span:nth-child(1) {
    color: #909091;
    font-size: 15px;
    margin: 5px 15px;
}

.evaluation .xing div span:nth-child(3) {
    font-size: 18px;
    color: #f4ae19;
    margin-left: 15px;
}

.evaluationBox {
    width: 95%;
    margin: 0px 2.5%;
}

.evaluationTitle {
    display: flex;
    align-items: center;
    position: relative;
}

.evaluationTitle img {
    width: 50px;
}

.evaluationTitle .fen {
    margin-left: 10px;
}

.evaluationTitle .time {
    position: absolute;
    right: 10px;
    top: 5px;
    color: #ccc;
    font-size: 12px;
}

.evaluationCenter {
    width: 80%;
    margin: 5px 0% 15px 15%;
    font-size: 14px;
}

.numIcon {
    position: absolute;
    width: 15px;
    height: 15px;
}

.list .goodsDelete {
    position: absolute;
    bottom: 5px;
    right: 10%;
    font-size: 14px;
    color: red;
}

/deep/.van-radio {
    width: 90%;
    margin: 10px 5%;
}

.saveOn {
    position: flex;
    bottom: 15px;
    width: 80%;
    margin: 15px auto;
    background: #4b9fe3;
    color: #fff;
    font-size: 14px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
}
</style>